import { Link } from '@remix-run/react'
import { type PropsWithChildren } from 'react'
import { COPYRIGHT } from '#app/data/config'
import { BLOG_URL } from '#app/env.remix/config'
import { type Role } from '#app/types/types'
import { type FooterI18nKeys } from './footer.server'

export type FooterLink = {
	href: string
	text: string
}

function ExternalLink({ children, href }: PropsWithChildren<{ href: string }>) {
	return (
		<p className="px-4">
			<a
				target="_blank"
				rel="noopener noreferrer"
				href={href}
				className="text-slate-600 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400"
			>
				{children}
			</a>
		</p>
	)
}

function InternalLink({
	children,
	dataCy,
	to,
}: PropsWithChildren<{
	dataCy?: string
	to: string
}>) {
	return (
		<p className="px-4">
			<Link
				data-cy={dataCy}
				to={to}
				className="text-slate-600 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400"
			>
				{children}
			</Link>
		</p>
	)
}

export type FooterProps = {
	guestLinks: FooterLink[]
	i18n: Record<FooterI18nKeys, string>
	locale: string
	otherSitesLinks: FooterLink[]
	role: Role
}

export default function Footer(props: FooterProps) {
	return (
		<footer className="mt-4 text-base leading-6">
			<div className="mx-auto max-w-7xl">
				<div className="flex flex-wrap items-baseline justify-center space-y-4 whitespace-nowrap sm:space-y-2">
					{props.role === 'guest' ? (
						<ExternalLink href={BLOG_URL}>
							{props.i18n.footer_blog}
						</ExternalLink>
					) : null}
					{/* <InternalLink
                        dataCy="f-about"
                        to={`/${props.locale}/about-us`}
                    >
                        {props.i18n.footer_about_us}
                    </InternalLink> */}
					<InternalLink dataCy="f-affiliate" to={`/${props.locale}/affiliate`}>
						{props.i18n.footer_affiliate}
					</InternalLink>
					{props.role === 'guest' ? (
						<InternalLink dataCy="f-contact" to={`/${props.locale}/contact-us`}>
							{props.i18n.footer_contact_us}
						</InternalLink>
					) : (
						<InternalLink
							dataCy="f-contact"
							to={`/${props.locale}/user/messages/admin`}
						>
							{props.i18n.footer_contact_us}
						</InternalLink>
					)}
					<InternalLink dataCy="f-faq" to={`/${props.locale}/faq`}>
						{props.i18n.footer_faq}
					</InternalLink>
					<InternalLink
						dataCy="f-privacy"
						to={`/${props.locale}/privacy-policy`}
					>
						{props.i18n.footer_privacy_policy}
					</InternalLink>
					<InternalLink dataCy="f-safety" to={`/${props.locale}/safety-tips`}>
						{props.i18n.footer_safety}
					</InternalLink>
					<InternalLink dataCy="f-terms" to={`/${props.locale}/terms-of-use`}>
						{props.i18n.footer_terms_of_use}
					</InternalLink>
					{props.role === 'guest' ? (
						<InternalLink
							dataCy="f-sitemap"
							to={`/${props.locale}/general/sitemap`}
						>
							{props.i18n.footer_sitemap}
						</InternalLink>
					) : null}
				</div>
				<div className="mt-2 flex flex-wrap items-baseline justify-center space-y-4 whitespace-nowrap sm:space-y-2">
					{props.otherSitesLinks.map((link) => (
						<ExternalLink key={link.href} href={link.href}>
							{link.text}
						</ExternalLink>
					))}
					{props.role === 'guest'
						? props.guestLinks.map((link) => (
								<InternalLink
									key={link.href}
									dataCy="f-guest"
									to={`/${props.locale}${link.href}`}
								>
									{link.text}
								</InternalLink>
							))
						: null}
				</div>
				<div className="my-4 text-center text-slate-600">
					&copy; {COPYRIGHT}
				</div>
			</div>
		</footer>
	)
}
